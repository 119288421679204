import React from "react"
import * as classes from  './WorkWithUs.module.css'
import WO_LOGO from '../../assets/images/logos/new_logo.png'

import ButtonLeft from '../../assets/images/others/line.png';
import ButtonRight from '../../assets/images/others/visit.png';
import LinkedIn from '../../assets/images/others/linkedin-logo.png';

import Content from '../../../content.json'

const WorkWithUs = () => {
    return(
        <div className={classes.OuterContainer} id="WorkWithUs">
            <div className={classes.ContentContainer}>
                <div className={classes.TextContainer}
                data-sal="fade"
                data-sal-delay="300"
                data-sal-easing="ease">
                    <p><span>{Content.careersSection.heading.split(' ')[0]}</span> {Content.careersSection.heading.split(' ').slice(1).join(" ")}</p>
                    <p>{Content.careersSection.content}</p>
                    <a href="https://weboasis.homerun.co/" target="_blank">
                        <button type="button" className={classes.ApplyButton}><img src={ButtonLeft} />APPLY<img src={ButtonRight} /></button>
                    </a>
                    <div className={classes.SocialIcons}>
                        <div><a href="https://www.linkedin.com/company/weboasis/" target="_blank"><img src={LinkedIn} /></a></div>
                    </div>
                </div>
            </div>
            <div className={classes.Footer}>
                <div className={classes.FooterContainer}>
                    <div>
                        <img src={WO_LOGO} alt="Weboasis logo" />
                    </div>
                    <div>{Content.footerSection.copyrights}</div>
                </div>
            </div>
        </div>
    )
}
export default WorkWithUs;