import * as React from "react"
import './index.css';
import { Helmet } from "react-helmet"

import Header from '../components/Header/Header';
import SectionOne from '../components/SectionOne/SectionOne';
//import SectionTwoOne from '../components/SectionTwoOne/SectionTwoOne';
import SectionTwoTwo from '../components/SectionTwoTwo/SectionTwoTwo';
import SectionThree from '../components/SectionThree/SectionThree';
import PortfolioSection from '../components/PortfolioSection/PortfolioSection';
import SectionFour from '../components/SectionFour/SectionFour';
//import SectionFive from '../components/SectionFive/SectionFive';
//import SectionSix from '../components/SectionSix/SectionSix';
import WOHOME_TREE from '../assets/images/compressed/WO_TREE_FULL.jpg';
import SpreadedParticles from '../components/Particles/SpreadedParticles';
import WorkWithUs from '../components/WorkWithUs/WorkWithUs';
import EmailSection from '../components/EmailSection/EmailSection';
import TeamSection from '../pages/team-section';

const IndexPage = () => {
  return (
    <div className="RootContainer">
      <Helmet
        title="Weboasis"
        titleTemplate={`%s | ${"Weboasis Labs"}`}
        defaultTitle="Weboasis"
        meta={[
          {
              name: "description",
              content: "Digital solutions, a class apart",
          },
          {
              property: `og:title`,
              content: "Weboasis",
          },
          {
              property: `og:description`,
              content: "Digital solutions, a class apart",
          },
        ]}
        htmlAttributes={{
            lang: 'en',
        }}
      /> 
      <Header />
      <SectionOne />
      <PortfolioSection />
      <SectionTwoTwo desktop={true} />
      <SectionThree />
      <SectionTwoTwo desktop={false} />
      <SectionFour />
      <TeamSection />
      <div className="lastTree" id="lastSection">
        <EmailSection />
        <WorkWithUs />
      </div>
      {/*<SpreadedParticles
        canvasId="canvasSectionRoot"
      />*/}
    </div>
  );
}
export default IndexPage



/*
<!-- Primary Meta Tags -->
<title>Weboasis</title>
<meta name="title" content="Weboasis">
<meta name="description" content="Digital solutions a class apart">

<!-- Open Graph / Facebook -->
<meta property="og:type" content="website">
<meta property="og:url" content="https://weboasis.io/">
<meta property="og:title" content="Weboasis">
<meta property="og:description" content="Digital solutions a class apart">
<meta property="og:image" content="">

<!-- Twitter -->
<meta property="twitter:card" content="summary_large_image">
<meta property="twitter:url" content="https://weboasis.io/">
<meta property="twitter:title" content="Weboasis">
<meta property="twitter:description" content="Digital solutions a class apart">
<meta property="twitter:image" content="">
*/