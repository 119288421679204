import React, { useRef, useState, useEffect } from "react";
import * as classes from "./SectionFour.module.css";
import Arrow from "../../assets/images/others/Group1button.png";

import Services_1 from "../../assets/images/compressed/Services_1.jpg";
import Services_2 from "../../assets/images/compressed/Services_2.jpg";
import Services_3 from "../../assets/images/compressed/Services_3.jpg";
import Services_4 from "../../assets/images/compressed/Services_4.jpg";

import Services_1_webp from "../../assets/images/compressed/Services_1.webp";
import Services_2_webp from "../../assets/images/compressed/Services_2.webp";
import Services_3_webp from "../../assets/images/compressed/Services_3.webp";
import Services_4_webp from "../../assets/images/compressed/Services_4.webp";

import ButtonLeft from "../../assets/images/others/line.png";
import ButtonRight from "../../assets/images/others/visit.png";

import Services_Default from "../../assets/images/compressed/Services_Default.jpg";
import Services_Default_webp from "../../assets/images/compressed/Services_Default.webp";

import { Link } from "react-scroll";
import Content from "../../../content.json";

const SectionFour = () => {
  const imgRef = useRef();
  const salRef = useRef();

  function support_format_webp(){
      const isBrowser = () => typeof window !== "undefined";
      if(isBrowser()){
          var elem = document.createElement('canvas');

          if (!!(elem.getContext && elem.getContext('2d')))
          {
              // was able or not to get WebP representation
              return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
          }
          else
          {
              // very old browser like IE 8, canvas not supported
              return false;
          }
      }else{
        return false;
      }
  }

  useEffect(() => {
    salRef.current.addEventListener("sal:in", ({ detail }) => {
      imgRef.current.classList.add(classes.AddAnimation);
    });
  }, []);

  const [startAnim, setStartAnim] = useState(false);
  const [sectionReached, setSectionReached] = useState(false);
  const Services_Default_Image = support_format_webp() ? Services_Default_webp : Services_Default;
  
  const Services_1_selected = support_format_webp() ? Services_1_webp : Services_1;
  const Services_2_selected = support_format_webp() ? Services_2_webp : Services_2;
  const Services_3_selected = support_format_webp() ? Services_3_webp : Services_3;
  const Services_4_selected = support_format_webp() ? Services_4_webp : Services_4;

  const services = [
    {
      name: Content.servicesPage["1"].name,
      description: Content.servicesPage["1"].description,
      points: Content.servicesPage["1"].points,
      background: Services_1_selected,
      height: "150px",
      mobileHeight: "250px"
    },
    /*{
			"name": "Mobile Applications",
		    "description":"We build Mobile applications that are smooth, swift and induce beautiful experiences. Our focus: reliable and robust apps.",
		    "points": ["React Native", "Progressive Web Apps"],
		    "background": Services_2,
		    "height": "100px",
			"mobileHeight": "150px"
		},*/
    {
      name: Content.servicesPage["2"].name,
      description: Content.servicesPage["2"].description,
      points: Content.servicesPage["2"].points,
      background: Services_2_selected,
      height: "150px",
      mobileHeight: "250px"
    },
    {
      name: Content.servicesPage["3"].name,
      description: Content.servicesPage["3"].description,
      points: Content.servicesPage["3"].points,
      background: Services_3_selected,
      height: "100px",
      mobileHeight: "100px"
    },
    {
      name: Content.servicesPage["4"].name,
      description: Content.servicesPage["4"].description,
      points: Content.servicesPage["4"].points,
      background: Services_4_selected,
      height: "100px",
      mobileHeight: "150px"
    },
  ];
  const [activeTabs, setActiveTabs] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(null);

  useEffect(() => {
    let tempJson = {};
    services.map(function (service, index) {
      if (index == 0) {
        tempJson["service" + index] = true;
      } else {
        tempJson["service" + index] = false;
      }
      if (index === services.length - 1) {
        setActiveTabs(tempJson);
      }
    });
  }, []);

  const isBrowser = () => typeof window !== "undefined";

  /*useEffect(()=>{
        if(isBrowser()){
            const loadImages = () => {
                if(window.scrollY >= document.getElementById("section4").offsetTop - 100 && window.scrollY <= document.getElementById("lastSection").offsetTop){
                	setSelectedIndex(0)
					/*const imageList = [Services_Default, Services_1, Services_2, Services_3, Services_4, Services_5];
					for(let i=0; i<imageList.length; i++){
						const img = new Image();
						img.onload = () => {
							console.log("loading",i)
						}
						img.src = imageList[i]
					}*
                }
                if(window.scrollY >= document.getElementById("lastSection").offsetTop - 300){
                	setSelectedIndex(null)
                }
                if(window.scrollY <= document.getElementById("section4").offsetTop - 100){
                	setSelectedIndex(null)
                }
            }
            window.addEventListener("scroll",()=>{
                loadImages();
            })
            loadImages();
        }
    },[])*/
  const setTabActive = (index) => {
    const currentState = activeTabs["service" + index];
    console.log(currentState)
    for (var key in activeTabs) {
      activeTabs[key] = false;
    }
    if (!currentState) {
      setActiveTabs({
        ...activeTabs,
        ["service" + index]: true,
      });
      setSelectedIndex(index);
    } else {
      setActiveTabs({ ...activeTabs });
      setSelectedIndex(-1);
    }
  };

  useEffect(() => {
    if (isBrowser()) {
      document.addEventListener("scroll", function (e) {
        if (
          document.getElementById("section4").offsetTop <
            window.scrollY + 500 &&
          !sectionReached
        ) {
          setSectionReached(true);
        }
      });
    }
    setTabActive(0);
  }, []);

  return (
    <React.Fragment>
      <div className={classes.BackgroundContainer}>
        <div></div>
        <img
          src={
            selectedIndex !== null
              ? selectedIndex === -1
                ? Services_Default_Image
                : services[selectedIndex].background
              : null
          }
        />
        <div></div>
      </div>
      <div className={classes.SectionFour} id="section4">
        <div className={classes.Container}>
          <div
            className={classes.StartingAnimation}
            data-sal="fade"
            data-sal-delay="300"
            data-sal-easing="ease"
            ref={salRef}
          >
            {services.map(function (service, index) {
              return (
                  <div
                    onClick={ startAnim ? ()=> {setStartAnim(false); setTimeout(function(){ setTabActive(index) },1000); } : ()=>{setTabActive(index)} }
                    className={
                      sectionReached
                        ? startAnim
                          ? classes.Service
                          : [classes.Service, classes.FlexStart].join(" ")
                        : null
                    }
                    style={ activeTabs['service'+index] ? {marginTop: "20px", marginBottom: "30px"} : {}}
                  >
                    <div
                      className={
                        activeTabs["service" + index]
                          ? [classes.ActiveTab, classes.ServiceTab].join(" ")
                          : classes.ServiceTab
                      }
                      //onClick={() => setTabActive(index)}
                      //onMouseOver={() => setTabActive(index)}
                    >
                      {service.name}
                    </div>
                    <div
                      className={classes.ServiceDescription}
                      style={
                        activeTabs["service" + index]
                          ? { height: "auto", marginTop: "20px" }
                          : {}
                      } //window.innerWidth < 768 ? service.mobileHeight : service.height
                    >
                      {service.description}
                      {service.points.length ? (
                        <>
                          <br />
                          <br />
                        </>
                      ) : null}
                      {service.points.map(function (point) {
                        return (
                          <div className={classes.DescPoint}>
                            <span>•</span>
                            {point}
                          </div>
                        );
                      })}
                    </div>
                    <img
                      src={Services_1}
                      key={service.background}
                      style={{ display: "none" }}
                    />
                  </div>
              );
            })}
            {/*<div className={classes.ActionButton}>
              <a href="/services">
                <button>
                  <img src={ButtonLeft} />
                  SEE MORE
                  <img src={ButtonRight} />
                </button>
              </a>
            </div>*/}
          </div>
          {/* services.map(function(service, index){
					return activeTabs['service'+index] ?
						<div className={classes.ImageContainer}>
							<div></div>
						</div> : null
				})*/}
        </div>
        <div className={classes.ScrollArrow}>
          <Link
            activeClass={classes.Active}
            to="team-section"
            spy={true}
            smooth={true}
            offset={0}
            duration={1000}
          >
            <img src={Arrow} alt="" ref={imgRef} />
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SectionFour;
