import React,{ useState, useEffect} from "react";
import * as THREE from 'three';
//import { BufferGeometryUtils } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
//import { Canvas, useFrame } from "react-three-fiber";

const NonSpreadedParticles=()=>{
  const isBrowser = () => typeof window !== "undefined";
  const [styles, setStyles] = useState({});
  useEffect(()=>{
    if(isBrowser()){

     if(window.innerWidth < 769){
        setStyles({display: "none"})
     }
     else{
        setStyles({overflow: "hidden", position: "relative", zIndex: "1"})
     }
     const prettyBackground = PrettyBackground(
        //Math.max( window.innerWidth, window.innerHeight ), // width of the square <canvas>
        window.innerWidth,
        window.innerHeight+window.innerHeight/2,
        'https://cdn.glitch.com/5c4f8a2b-76a1-4919-83a0-bb5b778736ef%2FCrystalGlow.png?v=1630938152046', // particle URL
        'https://cdn.glitch.com/bc2bf200-c8b2-4637-b85a-30978a04d86e%2Fground.glb?v=1630356193071', // glb model URL
        122, // number of particles
        18, // particles size
        0.004 // particles speed
      );
      

      // prettyBackground.domElement is the <canvas> dom element on which the webGL scene will be drawn.
      //document.querySelector('#container').appendChild(prettyBackground.domElement);
      //console.log(prettyBackground.domElement)

      // if you want to resize the <canvas> element, call prettyBackground.onResize with the new dimension.
      window.addEventListener("resize", () =>
        prettyBackground.onResize( /*Math.max(*/ window.innerWidth, window.innerHeight+window.innerHeight/2 )// )
      );
      
      // add a little interactivity
      prettyBackground.domElement.addEventListener("mousemove", e =>
        prettyBackground.onMouseMove(e)
      );

      // animate the scene drawn on the <canvas> element
      (function loop() {
        requestAnimationFrame(loop);
        prettyBackground.renderFrame();
      })();

      /* /////////////////////////////////// */
      
      
      
      
      
      
      /* ////// SCARY CODE YOU CAN COPY & PASTE ////// */

      function PrettyBackground(width, height, textureURL, modelURL, PARTICLES_NUMB, PARTICLES_SIZE, PARTICLES_SPEED) {

        /* GLSL SHADERS */

        const shaders = {
          vertexShader: `
            uniform float yDomain;
            uniform vec2 mouse;
            uniform float mousePower;
            uniform float size;
            uniform float resolution;
            varying vec4 vPos;

            void main() {
              float t = ( position.y / ( yDomain * 0.5 ) ) * -0.5 + 0.5;
              vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
              vec4 glPos = projectionMatrix * mvPosition;
              vec2 projectedVec = mouse * 100.0 * ( -mvPosition.z / 100.0 );
              float dist = distance( glPos.xy, projectedVec );
              vec2 finalPos = mix( glPos.xy, projectedVec, min( 0.5, max( 0.0, 3.0 / dist - 0.1 ) ) * mousePower );
              vPos = vec4( finalPos, glPos.z, glPos.w );
              gl_Position = vPos;
              gl_PointSize = size * ( resolution / 1024.0 ) * max( 0.5, 1.0 - ( 3.0 * mousePower ) / dist ) * ( 100.0 / -mvPosition.z );
            }
          `,

          fragmentShader: `
            uniform sampler2D map;
            varying vec4 vPos;

            void main() {
              vec4 texel = texture2D( map, gl_PointCoord );
              
              vec2 vCoords = vPos.xy;
              vCoords /= vPos.w;
              //Brightness of Crystals
              float light = 0.9 + 0.9 * ( 1.0 - length( vCoords ) );
              
              gl_FragColor = vec4( vec3( texel.xyz ) * light, texel.a );
            }
          `
        };

        /* BASIC SETUP */
        
        const api = {};
        
        let scopeWidth = width;
        let camera;
        
        const mouse = new THREE.Vector2( -1, 0 );
        const scene = new THREE.Scene();
        
        const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true, canvas: document.getElementById("canvasId") });
        renderer.setSize(width, height);
        api.domElement = renderer.domElement;
        
        /* DEPTH MASK MODEL */
        
        let ground;
        
        new GLTFLoader().load( modelURL, (glb) => {
          
          camera = glb.cameras[0];
          camera.near = 0.1;
          camera.far = 1200;
          onResize( width, height );
          
          ground = glb.scene.getObjectByName( 'Foreground' );
          
          // make the materials transaprent but still write to depth buffer
          glb.scene.traverse( child => {
            if ( child.material ) {
              child.material.onBeforeCompile = (shader) => {
                shader.fragmentShader = shader.fragmentShader.replace(
                  'gl_FragColor = vec4( outgoingLight, diffuseColor.a )',
                  'gl_FragColor = vec4( 0 )'
                )
              }
              child.material.side = THREE.DoubleSide;
              child.visible = false;
            }
          } );
          scene.add( glb.scene );

          makeParticles();
          
        } );
        
        /* PARTICLES */
        
        let positions, velocities, directions, waveTime, posAttrib, material;

        function makeParticles() {
        
          const raycaster = new THREE.Raycaster();
          raycaster.ray.direction.set( 0, -1, 0 );

          const PARTICLES_AREA = new THREE.Vector2( 150, 200 );

          const PARTICLES_DOMAIN = new THREE.Vector3( 150, 25, 180 );
          positions = [];
          velocities = [];
          directions = [];
          waveTime = [];

          for (let i = 0; i < PARTICLES_NUMB; i++) {

            raycaster.ray.origin.set(
              PARTICLES_AREA.x * Math.random() - PARTICLES_AREA.x * 0.5,
              200,
              PARTICLES_AREA.y * Math.random() - PARTICLES_AREA.y * 0.5
            );

            const pos = raycaster.intersectObject( ground )[0].point;
            pos.y += 0.5;

            positions.push( pos );
            velocities.push( 0.5 + Math.random() * 0.5 );
            directions.push( Math.sign( Math.random() - 0.5 ) );
            waveTime.push( Math.PI * 2 * Math.random() );
          }

          const particlesGeom = new THREE.BufferGeometry();
          posAttrib = new THREE.BufferAttribute(
            new Float32Array(PARTICLES_NUMB * 3),
            3
          );
          particlesGeom.setAttribute("position", posAttrib);
          
          //

          material = new THREE.ShaderMaterial({
            uniforms: {
              map: {
                value: new THREE.TextureLoader().load( textureURL )
              },
              yDomain: { value: PARTICLES_DOMAIN.y },
              size: { value: PARTICLES_SIZE },
              mouse: { value: mouse },
              mousePower: { value: 1 },
              resolution: { value: width }
            },
            vertexShader: shaders.vertexShader,
            fragmentShader: shaders.fragmentShader,
            transparent: true,
            depthWrite: false
          });

          //

          const particles = new THREE.Points(particlesGeom, material);
          scene.add(particles);
          
        }

        /* RESIZING */

        api.onResize = onResize;
        
        function onResize( width, height ) {
          camera.aspect = 1;
          camera.updateProjectionMatrix();
          renderer.setSize( width, height );
          scopeWidth = width;
          if ( material ) material.uniforms.resolution.value = width;
        };

        /* ANIMATION */

        const CAM_MOV_DOMAIN = 3;
        let targetCamX = 0;
        const clock = new THREE.Clock();
        const NOMINAL_FRAME_DELTA = 1 / 60;
        const targetMouse = new THREE.Vector2();

        api.renderFrame = function renderFrame() {
          const timeRatio = Math.min( clock.getDelta() / NOMINAL_FRAME_DELTA, 3 );
          animateParticles( timeRatio );
          animateMouse( timeRatio );
          if ( camera ) renderer.render(scene, camera);
        };

        function animateParticles( timeRatio ) {
          if ( !positions || !velocities || !waveTime ) return
          
          for (let i = 0; i < PARTICLES_NUMB; i++) {
            
            waveTime[i] += velocities[i] * PARTICLES_SPEED * 3 * timeRatio;
            
            posAttrib.setXYZ(
              i,
              positions[i].x + Math.sin( directions[i] * waveTime[i] ),
              positions[i].y + Math.cos( waveTime[i] ),
              positions[i].z
            );
            
          }
          
          posAttrib.needsUpdate = true;
        }
        
        function animateMouse( timeRatio ) {
          mouse.x += ( targetMouse.x - mouse.x ) * 0.1 * timeRatio;
          mouse.y += ( targetMouse.y - mouse.y ) * 0.1 * timeRatio;
          if ( material ) material.uniforms.mousePower.value = 1 - targetMouse.distanceTo( mouse ) / 2;
        }
        
        api.onMouseMove = function onMouseMove( e ) {
          const rect = e.target.getBoundingClientRect();
          targetMouse.x = ( ( e.clientX - rect.left ) / width ) * 2 - 1;
          targetMouse.y = ( ( e.clientY - rect.top ) / height ) * - 2 + 1;
        }

        //

        return api;
      }
    }
    },[])
  return(
    <div style={styles}>
      <canvas id="canvasId"></canvas>
    </div>
  )
}
export default NonSpreadedParticles;