import React, {useEffect} from "react";
import * as classes from "./EmailSection.module.css";
import WO_LOGO from "../../assets/images/logos/new_logo.png";

import ButtonLeft from "../../assets/images/others/line.png";
import ButtonRight from "../../assets/images/others/visit.png";

//import SpreadedParticles from '../Particles/SpreadedParticles';
import Content from "../../../content.json";

const EmailSection = () => {

    const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    const submitForm = (e) => {
        // dep id : AKfycbylICbsqlAmcbUkNn3wOnOVF95eyzPaXSN4xOxcon7yzGHeaxCDrBNlhzHdxfIq3vUv
        const scriptURL = 'https://script.google.com/macros/s/AKfycbylICbsqlAmcbUkNn3wOnOVF95eyzPaXSN4xOxcon7yzGHeaxCDrBNlhzHdxfIq3vUv/exec';
        const form = document.forms['submit-to-google-sheet'];
        const input = document.getElementById("emailInput");
        const flipBox = document.getElementById("flipBoxId");
        const successBox = document.getElementById("successBox");
        const name = document.getElementById("nameInput");
        e.preventDefault();

        if(!pattern.test(input.value)){
            return false;
        }else{
            successBox.innerHTML = "You'll hear from us soon "+name.value+"!"
            flipBox.style.transform = "rotateY(180deg)";
            fetch(scriptURL, { method: 'POST', body: new FormData(form)})
              .then(response => {
                form.reset()
                flipBox.style.transform = "rotateY(0deg)";
              })
              .catch(error => {
                flipBox.style.transform = "rotateY(0deg)";
              })
        }    
    }


  return (
    <div className={classes.OuterContainer} id="EmailSection">
      <div className={classes.ContentContainer}>
        <div
          className={classes.TextContainer}
          data-sal="fade"
          data-sal-delay="300"
          data-sal-easing="ease"
        >
          <p><span>{Content.emailSection.heading.split(' ')[0]}</span> {Content.emailSection.heading.split(' ').slice(1).join(" ")}</p>
          <p>{Content.emailSection.content}</p>
        </div>
      </div>
        <div className={classes.FormContainer}>
            <form onSubmit={submitForm} id="submit-to-google-sheet">
              <div className={classes.FlipContainer}>
                <div className={classes.FlipBox} id="flipBoxId">
                  <div className={classes.FieldContainer}>
                    <div className={classes.NameContainer}>
                      <input id="nameInput" name="Name" type="text" placeholder="Name" required />
                    </div>
                    <div className={classes.EmailContainer}>
                      <input id="emailInput" name="Email" type="email" placeholder="E-mail" required />
                    </div>
                    <div className={classes.MessageContainer}>
                      <textarea name="Message" placeholder="Tell us a bit about your project" required />
                    </div>
                  </div>
                  <div className={classes.SuccessBox} id="successBox">
                    
                  </div>
                </div>
              </div>
                <div className={classes.SendButtonContainer}>
                  <button type="submit">
                    <img src={ButtonLeft} alt="left line" />
                    <span id="submit-form-button">SUBMIT</span>
                    <img src={ButtonRight} alt="Right Arrow" />
                  </button>
                </div>
                {/*<div className={classes.ResultButtonContainer} id="result-button-container">
                  <button type="button" id="result-button">
                  You'll hear from us soon!
                  </button>
                </div>*/}
            </form>
        </div>
      {/*<div className={classes.Footer}>
        <div className={classes.FooterContainer}>
          <div>
            <img src={WO_LOGO} alt="Weboasis logo" />
          </div>
          <div>{Content.footerSection.copyrights}</div>
        </div>
      </div>*/}
    </div>
  );
};
export default EmailSection;
