// extracted by mini-css-extract-plugin
export var SectionThree = "SectionThree-module--SectionThree--1DYja";
export var WrapperContainer = "SectionThree-module--WrapperContainer--326Q8";
export var ScrollContainer = "SectionThree-module--ScrollContainer--3x10J";
export var ProjectContainer = "SectionThree-module--ProjectContainer--uuA1d";
export var Project = "SectionThree-module--Project--3U6tS";
export var Container = "SectionThree-module--Container--3QruU";
export var Animate = "SectionThree-module--Animate--3Cl15";
export var ProjectLogo = "SectionThree-module--ProjectLogo--2yPAS";
export var ProjectDescription = "SectionThree-module--ProjectDescription--2owxE";
export var ProjectDisplay = "SectionThree-module--ProjectDisplay--181ky";
export var Image = "SectionThree-module--Image--1YIb8";
export var IpadBackground = "SectionThree-module--IpadBackground--3arnb";
export var Description = "SectionThree-module--Description--3HMvh";
export var ActionButton = "SectionThree-module--ActionButton--1ltk2";
export var Strip = "SectionThree-module--Strip--2Nnn0";
export var Zero = "SectionThree-module--Zero--22Hng";
export var PriorAnimate = "SectionThree-module--PriorAnimate--2BAnE";
export var FadeIn = "SectionThree-module--FadeIn--3uTMl";
export var LeftArrow = "SectionThree-module--LeftArrow--2qE1h";
export var RightArrow = "SectionThree-module--RightArrow--2R9n2";
export var BlackArrow = "SectionThree-module--BlackArrow--3a5NS";
export var MovementArrows = "SectionThree-module--MovementArrows--3W6QP";
export var arrow = "SectionThree-module--arrow--zNXMW";
export var ContentContainer = "SectionThree-module--ContentContainer--1agrp";
export var TextContainer = "SectionThree-module--TextContainer--39X83";
export var ScrollArrow = "SectionThree-module--ScrollArrow--13WLH";
export var AddAnimation = "SectionThree-module--AddAnimation--3Urg7";
export var TopDown = "SectionThree-module--TopDown--LDfNz";
export var FadeOut = "SectionThree-module--FadeOut--30wHR";
export var MobileArrows = "SectionThree-module--MobileArrows--3J5Fv";
export var Slides = "SectionThree-module--Slides--3VxAM";
export var SlidesBlock = "SectionThree-module--SlidesBlock--379bS";
export var Slide = "SectionThree-module--Slide--1ReRN";
export var BrightnessFull = "SectionThree-module--BrightnessFull--bJxwn";