// extracted by mini-css-extract-plugin
export var PortfolioSection = "PortfolioSection-module--PortfolioSection--p1RPm";
export var WrapperContainer = "PortfolioSection-module--WrapperContainer--n-pPy";
export var ScrollContainer = "PortfolioSection-module--ScrollContainer--1tvBZ";
export var ProjectContainer = "PortfolioSection-module--ProjectContainer--JoF4X";
export var Project = "PortfolioSection-module--Project--2IVfR";
export var Container = "PortfolioSection-module--Container--1iJO0";
export var Animate = "PortfolioSection-module--Animate--1pbMa";
export var ProjectLogo = "PortfolioSection-module--ProjectLogo--5PMOX";
export var ProjectDescription = "PortfolioSection-module--ProjectDescription--3OA0L";
export var ProjectDisplay = "PortfolioSection-module--ProjectDisplay--3IERa";
export var PortfolioBackground = "PortfolioSection-module--PortfolioBackground--221IL";
export var LinkRhombus = "PortfolioSection-module--LinkRhombus--hdYgV";
export var ImageRhombus = "PortfolioSection-module--ImageRhombus--3E9OR";
export var ImageDivRhombus = "PortfolioSection-module--ImageDivRhombus--1Ipgi";
export var ProjectBackgroundRhombus = "PortfolioSection-module--ProjectBackgroundRhombus--1wh93";
export var Image = "PortfolioSection-module--Image--2N46h";
export var ImageDiv = "PortfolioSection-module--ImageDiv--3bfvT";
export var Dot = "PortfolioSection-module--Dot--C2hFJ";
export var ProjectBackground = "PortfolioSection-module--ProjectBackground--uQ11D";
export var BorderDotMove = "PortfolioSection-module--BorderDotMove--1qFVI";
export var IpadBackground = "PortfolioSection-module--IpadBackground--1hUYh";
export var Description = "PortfolioSection-module--Description--14Kq8";
export var ActionButton = "PortfolioSection-module--ActionButton--2rtwy";
export var Strip = "PortfolioSection-module--Strip--2H8CQ";
export var Zero = "PortfolioSection-module--Zero--2xEDO";
export var PriorAnimate = "PortfolioSection-module--PriorAnimate--OqCiM";
export var FadeIn = "PortfolioSection-module--FadeIn--1W1-t";
export var LeftArrow = "PortfolioSection-module--LeftArrow--1N92g";
export var RightArrow = "PortfolioSection-module--RightArrow--1KshG";
export var BlackArrow = "PortfolioSection-module--BlackArrow--339nl";
export var MovementArrows = "PortfolioSection-module--MovementArrows--F1Kzq";
export var arrow = "PortfolioSection-module--arrow--wn7zO";
export var ContentContainer = "PortfolioSection-module--ContentContainer--102hm";
export var TextContainer = "PortfolioSection-module--TextContainer--10B9X";
export var ScrollArrow = "PortfolioSection-module--ScrollArrow--2UY4q";
export var AddAnimation = "PortfolioSection-module--AddAnimation--27IOR";
export var TopDown = "PortfolioSection-module--TopDown--3jv3c";
export var TopDown2 = "PortfolioSection-module--TopDown2--2GwJe";
export var FadeOut = "PortfolioSection-module--FadeOut--3KLHj";
export var MobileArrows = "PortfolioSection-module--MobileArrows--3SnlL";
export var Slides = "PortfolioSection-module--Slides--3KJbk";
export var SlidesBlock = "PortfolioSection-module--SlidesBlock--3-XUJ";
export var Slide = "PortfolioSection-module--Slide--2icQz";
export var HideDesktop = "PortfolioSection-module--HideDesktop--2ei5h";
export var clipMe = "PortfolioSection-module--clipMe--2Gd8y";
export var BrightnessFull = "PortfolioSection-module--BrightnessFull--mGO2_";
export var DropShadow = "PortfolioSection-module--DropShadow--3l9QG";