import React, { useEffect, useState, useRef } from "react"
import * as classes from  './SectionOne.module.css'
import logo from '../../assets/images/logos/weboasis_logo.png'
import logo2 from '../../assets/images/logos/weboasis_font.png'
import NonSpreadedParticles from "../Particles/NonSpreadedParticles"
import WO_LOGO from '../../assets/images/logos/new_logo.png'
import Arrow from '../../assets/images/others/Group1button.png';

import { Link } from "react-scroll";

import Content from '../../../content.json'

const SectionOne=()=>{
    
    /*const [animatedText, setAnimatedText] = useState("");
    const [animId, setAnimId] = useState(0)
    const AnimRef = useRef(null)

    const restartAnimation = (spans) => {
        const texts = [
            "Web Applications",
            "Web3 Solutions",
            "Smart Contracts"
        ]
        setAnimatedText(texts[animId])
        let anim_id = 0

        if(animId == texts.length - 1){
            anim_id = 0
        }else{
            anim_id = animId+1
        }
        AnimRef.current.classList.add(classes.FadeText)
        setTimeout(function(){
            AnimRef.current.classList.remove(classes.FadeText);
        },500);
        setTimeout(function(){
            setAnimId(anim_id)
        },3000)
    }

    useEffect(()=>{
        restartAnimation()
    },[animId])*/
    
    function support_format_webp(){
        const isBrowser = () => typeof window !== "undefined";
        if(isBrowser()){
            var elem = document.createElement('canvas');

            if (!!(elem.getContext && elem.getContext('2d')))
            {
                // was able or not to get WebP representation
                return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
            }
            else
            {
                // very old browser like IE 8, canvas not supported
                return false;
            }
        }
    }

    useEffect(()=>{    
        const el = document.getElementById("backgroundId");

        el.addEventListener("mousemove", (e) => {
          el.style.backgroundPositionX = -e.offsetX/50 + "px";
          el.style.backgroundPositionY = -e.offsetY/50 + "px";
        });
    })

    useEffect(()=>{
        if(document.getElementById("SectionOneTextId")){
            //setTimeout(function(){
                document.getElementById("SectionOneTextId").style.opacity = "1";
                const spans = document.getElementById("SectionOneAnimationLine").getElementsByTagName("span");
                    for(let i=0; i<spans.length; i++){
                        setTimeout(function(){
                            spans[i].style.opacity = "1";
                            spans[i].style.marginLeft = "0";
                        },300*(i+1))
                    }
            //},2000)
        }
    },[])

    return(
        <div className={classes.SectionOne}>
            <div className={classes.StarBackground} id="backgroundId">
                <div className={support_format_webp() ? [classes.Container, classes.Webp].join(' ') : classes.Container}>
                  <div className={classes.ParentContainer}>
                    <div className={classes.FontLogoContainer}>
                        <div className={classes.FontLogo} id="SectionOneTextId">
                            {/*<img src={logo2} alt="Weboasis font"/>*/}
                            <div className={classes.FlipContent}>
                                <div className={classes.flip3}>
                                    <div><div>{Content.landingPage.flippingContent["1"]}</div></div>
                                    <div><div>{Content.landingPage.flippingContent["2"]}</div></div>
                                    <div><div>{Content.landingPage.flippingContent["3"]}</div></div>
                                    <div><div>{Content.landingPage.flippingContent["1"]}</div></div>
                                    <div><div>{Content.landingPage.flippingContent["2"]}</div></div>
                                    <div><div>{Content.landingPage.flippingContent["3"]}</div></div>
                                </div>
                            </div>
                            <p id="SectionOneAnimationLine">
                                <span>{Content.landingPage.sideAnimLine["1"]}</span>&nbsp;
                                <span>{Content.landingPage.sideAnimLine["2"]}</span>&nbsp;
                                <span>{Content.landingPage.sideAnimLine["3"]}</span>
                            </p>
                        </div>
                    </div>
                  </div>
                  <p className={classes.ScrollText}>
                  <Link
                    activeClass={classes.Active}
                    to="PortfolioSection"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={1000}>
                    <span></span>
                    <img src={Arrow} alt="" />
                  </Link>
                  </p>
                  <NonSpreadedParticles/>
                </div>
            </div>
        </div>
        
        
    )
}
export default SectionOne;