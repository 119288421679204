// extracted by mini-css-extract-plugin
export var OuterContainer = "EmailSection-module--OuterContainer--3m7wZ";
export var ContentContainer = "EmailSection-module--ContentContainer--2xpK0";
export var TextContainer = "EmailSection-module--TextContainer--2G_hP";
export var FormContainer = "EmailSection-module--FormContainer--3IJNt";
export var NameContainer = "EmailSection-module--NameContainer--1xDvT";
export var EmailContainer = "EmailSection-module--EmailContainer--3cRfP";
export var MessageContainer = "EmailSection-module--MessageContainer--2Xiy4";
export var SendButtonContainer = "EmailSection-module--SendButtonContainer--ZcEcI";
export var FieldContainer = "EmailSection-module--FieldContainer--xvZdp";
export var ResultButtonContainer = "EmailSection-module--ResultButtonContainer--3p3Qi";
export var FlipContainer = "EmailSection-module--FlipContainer--1iCOv";
export var FlipBox = "EmailSection-module--FlipBox--3_2At";
export var SuccessBox = "EmailSection-module--SuccessBox--35f4P";
export var Footer = "EmailSection-module--Footer--2MknM";
export var FooterContainer = "EmailSection-module--FooterContainer--10lRH";