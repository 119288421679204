import React, {useRef, useState, useEffect} from 'react';
import * as classes from './SectionThree.module.css';

import KMTLogo from '../../assets/images/projects/kmt_logo.png';
import KMTIpad from '../../assets/images/projects/KMT_new_c.png';

import RSLogo from '../../assets/images/projects/rs_logo.png';
import RSIpad from '../../assets/images/projects/rs_ipad_c.png';
import Arrow from '../../assets/images/others/Group1button.png';
import CryptoPodzLogo from '../../assets/images/projects/cp_logo.png';
import BPIpad from '../../assets/images/projects/bp_ipad.png';
import CPIpad from '../../assets/images/projects/CP_c.png';
import CCIpad from '../../assets/images/projects/CC_ipad.png';
import CCLogo from '../../assets/images/projects/CC_logo.png';
import NextArrowThin from '../../assets/images/others/arrowLayer.png';
import NextArrow from '../../assets/images/others/right-arrow-pink.png';

import ButtonLeft from '../../assets/images/others/line.png';
import ButtonRight from '../../assets/images/others/visit.png';

import BlueDiamond from '../../assets/images/others/blue-diamond.png';
import WhiteDiamondLeft from '../../assets/images/others/white-diamond-left.png';
import WhiteDiamondTop from '../../assets/images/others/white-diamond-top.png';

import { Link } from "react-scroll";
import Content from '../../../content.json'


import { ProjectsData } from '../../components/PortfolioSection/ProjectsData';

//import SpreadedParticles from '../Particles/SpreadedParticles';

const SectionThree = () => {

    const imgRef = useRef();
    const salRef = useRef();

	const scrollRef = useRef();
	const containerRef = useRef();

	const isBrowser = () => typeof window !== "undefined";
   	const listOfWidths = [];
	for(let i=0;i<ProjectsData.length;i++){
		if(isBrowser()){
			listOfWidths.push(i*window.innerWidth)
		}
	}

    useEffect(()=>{
        /*salRef.current.addEventListener('sal:in', ({ detail }) => {
          imgRef.current.classList.add(classes.AddAnimation);
        });*/

        document.getElementById("project"+ProjectsData[0].id).classList.add(classes.Animate);
    },[])


	const [classAdded, setClassAdded] = useState(false)
	const [projectCurrent, setProject] = useState(0)
    /*const checkScroll = () => {
    	if(project && isBrowser()){
            if(window.scrollY >= document.getElementById("section2_2").offsetTop + window.innerHeight/3  &&  window.scrollY <= document.getElementById("section4").offsetTop - window.innerHeight/3){
            	document.getElementById("stripID"+project).classList.remove(classes.Zero);
            }else{
            	document.getElementById("stripID"+project).classList.add(classes.Zero);
            }
        }
    }
    window.addEventListener("scroll",()=>{
        checkScroll();
    })*/

	//console.log(project)
	/*function scrollFurther(elm, sl, finish, callback) {
			var cicle = setInterval(function(e = elm, c = callback) {
				elm.scrollLeft += 1;
				console.log(e.scrollLeft, finish)
				if (e.scrollLeft >= finish) {
					e.scrollLeft = finish;
					c(e.scrollLeft);
					clearInterval(cicle);
				}
			}, 10);
	}

	function scrollBack(elm, sl, finish, callback) {
			var cicle = setInterval(function(e = elm, c = callback) {
				elm.scrollLeft -= 1;
				console.log(e.scrollLeft, finish)
				if (e.scrollLeft <= finish) {
					e.scrollLeft = finish;
					c(e.scrollLeft);
					clearInterval(cicle);
				}
			}, 10);
	}*/
	const projectChange = (type) => {
		if(isBrowser()){
	        if(type === "right"){
				scrollRef.current.scrollLeft += window.innerWidth;
			}else if(type === "left"){
				scrollRef.current.scrollLeft -= window.innerWidth;
			}
	        //console.log(listOfWidths, scrollRef.current.scrollLeft)

			let min = Math.abs(listOfWidths[0] - scrollRef.current.scrollLeft);
	        let pos = 0;

	        for(let i=1; i<listOfWidths.length; i++){
	        	//document.getElementById("project"+i).style.visibility = "hidden";
	        	if(Math.abs(listOfWidths[i] - scrollRef.current.scrollLeft) < min){
	        		min = Math.abs(scrollRef.current.scrollLeft - listOfWidths[i]);
	        		pos = i;
	        	}
	        }
/*	        if(type === "right" && pos <= listOfWidths.length && pos > 0){
	      		document.getElementById("project"+(pos-1)).classList.add(classes.PriorAnimate);
	    	}
	    	if(type === "left" && pos < listOfWidths.length-1){
	    		document.getElementById("project"+(pos+1)).classList.remove(classes.Animate);
	    		if(pos >= 0){document.getElementById("project"+(pos)).classList.remove(classes.PriorAnimate);}
	    	}
*/
	        ProjectsData.map(function(item){
	        	document.getElementById("project"+item.id).classList.remove(classes.Animate);
	        })

	        if(pos >= 0 && pos <= listOfWidths.length){
	        	document.getElementById("project"+pos).classList.add(classes.Animate);
	        }
	        setProject(pos)
	    }
	}

    useEffect(()=>{
    	//var _scrollTimeout = null;
    	//var done = true;
        scrollRef.current.addEventListener('wheel', (e) => {
        	if(e.deltaX != 0){
				e.preventDefault();        		
        	}
        })
		var container = document.querySelector("#sectionThree");

		  container.addEventListener("touchstart", (e)=>startTouch(e), {passive: false});
		  container.addEventListener("touchmove", (e)=>moveTouch(e), {passive: false});

		  // Swipe Up / Down / Left / Right
		  var initialX = null;
		  var initialY = null;

		  function startTouch(e) {
		    initialX = e.touches[0].clientX;
		    initialY = e.touches[0].clientY;
		    //console.log(initialY, initialX)
		  };

		  function moveTouch(e) {
		    if (initialX === null) {
		      return;
		    }

		    if (initialY === null) {
		      return;
		    }

		    var currentX = e.touches[0].clientX;
		    var currentY = e.touches[0].clientY;

		    var diffX = initialX - currentX;
		    var diffY = initialY - currentY;

		    if (Math.abs(diffX) > Math.abs(diffY)) {
		      // sliding horizontally
		      e.preventDefault();
		      if (diffX > 0) {
		        // swiped left
//		        console.log("swiped left");
		      } else {
		        // swiped right
//		        console.log("swiped right");
		      }  
		    } else {
		      // sliding vertically
		      if (diffY > 0) {
		        // swiped up
//		        console.log("swiped up");
		      } else {
		        // swiped down
//		        console.log("swiped down");
		      }  
		    }

		    //initialX = null;
		    //initialY = null;

		    //e.preventDefault();
		  };
        	/*console.log(scrollRef.current.scrollLeft, window.innerWidth)
            if(scrollRef.current.offsetLeft < window.scrollX - 100 && !classAdded){
                setClassAdded(true)
            }*
            //setClassAdded(false)
            //if(done){
            let min = Math.abs(listOfWidths[0] - scrollRef.current.scrollLeft);
            let pos = 0;
            for(let i=1; i<listOfWidths.length; i++){
            	//document.getElementById("project"+i).style.visibility = "hidden";
            	if(Math.abs(listOfWidths[i] - scrollRef.current.scrollLeft) < min){
            		min = Math.abs(scrollRef.current.scrollLeft - listOfWidths[i]);
            		pos = i;
            	}
            }
        	if(e.deltaX > 0 && pos < listOfWidths.length && pos > 1){
        		document.getElementById("project"+(pos-1)).classList.add(classes.PriorAnimate);
        	}
        	if(e.deltaX < 0 && pos < listOfWidths.length-1){
        		console.log("project"+pos)
        		document.getElementById("project"+(pos+1)).classList.remove(classes.Animate);
        		if(pos > 0){document.getElementById("project"+(pos)).classList.remove(classes.PriorAnimate);}
        	}
            //if(document.getElementById("project"+pos)){document.getElementById("project"+pos).style.visibility = "visible";}
            //clearTimeout(_scrollTimeout);
		    //_scrollTimeout = setTimeout(function() {
        		console.log("Haven't scrolled in 250ms");
	            if(pos > 0 && pos < listOfWidths.length){
	            	document.getElementById("project"+pos).classList.add(classes.Animate);
	            	//done = false;
	            	//setClassAdded(true)
	            }
			//}, 300);
			//}
        });*/
    },[])

/*	useEffect(()=>{
		const scrollContainer = scrollRef.current;
		const dataContainer = containerRef.current;
			scrollContainer.addEventListener("wheel", (evt) => {
				    if( evt.deltaY >= 0 ){
				      //if(scrollContainer.scrollLeft < window.innerWidth*(ProjectsData.length - 1)){
				        evt.preventDefault();
				        //var finish = scrollContainer.scrollLeft + window.innerWidth;
				        //scrollContainer.scrollLeft += evt.deltaY;
					    //scrollContainer.scrollLeft += window.innerWidth;
					    /*scrollFurther(scrollContainer, window.innerWidth, finish, function (value) {
							console.log("Scroll finished (in " + value + " px)");
						});*
						projectChange(dataContainer, scrollContainer, "right");
				    }else{
				      //if(scrollContainer.scrollLeft > 0){
				        evt.preventDefault();
				        //var finish = scrollContainer.scrollLeft - window.innerWidth;
				        //scrollContainer.scrollLeft += evt.deltaY;
					    //scrollContainer.scrollLeft -= window.innerWidth;
					    /*scrollBack(scrollContainer, window.innerWidth, finish, function (value) {
							console.log("Scroll finished (in " + value + " px)");
						});*
						projectChange(dataContainer, scrollContainer, "left");
				    }
			});
	})*/
	const nextSlide = (type, times) => {
		for(let i=0; i<times; i++){
			projectChange(type);
		}
	}
	return (
		<div className={classes.SectionThree} id="sectionThree">
		<div>
	        <div className={classes.ContentContainer}>
        	<Link
	            activeClass={classes.Active}
	            to="section2_2_mobile"
	            spy={true}
	            smooth={true}
	            offset={0}
	            duration={1000}
	    	>
	            <div className={classes.TextContainer}
	            data-sal="fade"
                data-sal-delay="300"
                data-sal-easing="ease"
                ref={salRef}>
	                <p><span>{Content.projectsPage.landingPage.heading.split(' ')[0]}</span> {Content.projectsPage.landingPage.heading.split(' ').slice(1).join(' ')}</p>
	                <p>{Content.projectsPage.landingPage.content}</p>
                	<p>
                		<img src={Arrow} alt="" />
                	</p>
	            </div>
	        </Link>
	            {/*<SpreadedParticles
	            	canvasId="canvasSectionThree"
	            />*/}
	        </div>
		</div>
		<div className={classes.WrapperContainer}>
		{/*<div className={classes.MovementArrows}>
			{ projectCurrent == 0 || projectCurrent == ProjectsData.length ?
				null
				: <img
					src={NextArrow}
					className={/project == 1 || project == 2 ? [classes.arrow, classes.LeftArrow, classes.BlackArrow].join(' ') : /[classes.arrow, classes.LeftArrow].join(' ')}
					alt=""
					onClick={()=>nextSlide("right")}
				 />
			}
			{ projectCurrent == 0 ?
				null
				: <img
					src={NextArrow}
					className={/project == 1 || project == 2 ? [classes.arrow, classes.RightArrow, classes.BlackArrow].join(' ') : /[classes.arrow, classes.RightArrow].join(' ')}
					alt=""
					onClick={()=>nextSlide("left")}
				 />
			}
		</div>*/}
		<div className={classes.Slides}>
			<div className={classes.SlidesBlock}>
				{ProjectsData.map(function(item, index){
					return <div className={classes.Slide} onClick={ projectCurrent > index ? () => nextSlide("left", projectCurrent-index) : () => nextSlide("right", index - projectCurrent)}>
						<img src={item.display_image} />
					</div>											
				})}
			</div>
		</div>
		<div ref={scrollRef} className={classes.ScrollContainer} id="sectionThree_Sub">
			<div className={classes.ProjectContainer}>
			{/*<svg id="leftArrow" className={[classes.arrow, classes.LeftArrow].join(' ')} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" onClick={()=>nextSlide("left")}>
			    <g stroke-linejoin="round" stroke-linecap="round" >
			      <circle r="46" cx="50" cy="50" />
			      <polyline points="60 25, 30 50, 60 75" ></polyline>
			    </g>
			  </svg>
			  <svg id="rightArrow" className={[classes.arrow, classes.RightArrow].join(' ')} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" onClick={()=>nextSlide("right")}>
			    <g stroke-linejoin="round" stroke-linecap="round" >
			      <circle r="46" cx="50" cy="50" />
			      <polyline points="40 25, 70 50, 40 75" ></polyline>
			    </g>
			</svg>*/}
	    	{ProjectsData.map(function(project){
				return <div className={ classes.Project } id={"project"+project.id}>
						{/*<div className={ classAdded ? [classes.Animate, classes.Strip].join(' '): classes.Strip} style={{backgroundColor: project.strip_color}} id={"stripID"+project.id}></div>*/}
						<div className={classes.Swipe}></div>
						<div className={classAdded ? [classes.Animate, classes.Container].join(' ') : classes.Container} ref={containerRef}>
							<div className={ classes.ProjectDescription }>
								{/*<div className={classes.ProjectLogo}>
									<img src={project.logo} alt="" />
								</div>*/}
								<div className={classes.Description}>
									{project.description}
								</div>
								<div className={classes.ActionButton}>
									<a href={project.website} target="_blank">
										<button style={project.website == "" ? {opacity: "0"} : {}}><img src={ButtonLeft} />VISIT<img src={ButtonRight} /></button>
									</a>
								</div>
								{/* Arrows */}
								<div className={[classes.MovementArrows, classes.MobileArrows].join(' ')}>
									{ projectCurrent == ProjectsData.length-1 ?
										null
										: <img
											src={NextArrow}
											className={/*project == 1 || project == 2 ? [classes.arrow, classes.LeftArrow, classes.BlackArrow].join(' ') : */[classes.arrow, classes.LeftArrow].join(' ')}
											alt=""
											onClick={()=>nextSlide("right", 1)}
										 />
									}
									{ projectCurrent == 0 ?
										null
										: <img
											src={NextArrow}
											className={/*project == 1 || project == 2 ? [classes.arrow, classes.RightArrow, classes.BlackArrow].join(' ') : */[classes.arrow, classes.RightArrow].join(' ')}
											alt=""
											onClick={()=>nextSlide("left", 1)}
										 />
									}
								</div>
								{/* Arrows */}
							</div>
							<div className={ classAdded ? [classes.Animate, classes.ProjectDisplay].join(' ') : classes.ProjectDisplay}>
								<div className={classes.ProjectLogo}>
									<img src={project.logo} alt="" />
								</div>
								<div className={classes.IpadBackground}>
									<img src={project.diamond} />
								</div>
								<div className={classes.Image}>
									<img src={project.display_image} alt="" />
									{ project.previous_display_image != "" ? <img src={project.previous_display_image} alt="" /> : null }
								</div>
							</div>
						</div>
				</div>
	    	})}
	    	</div>
	    </div>
		</div>
		{/*<div className={classes.ScrollArrow}>
		<Link
            activeClass={classes.Active}
            to="section4"
            spy={true}
            smooth={true}
            offset={0}
            duration={1000}
    	><img src={Arrow} alt="" ref={imgRef} /></Link>
		</div>*/}
		</div>
	);
}

export default SectionThree;