// extracted by mini-css-extract-plugin
export var SectionOne = "SectionOne-module--SectionOne--dRZ6p";
export var StarBackground = "SectionOne-module--StarBackground--2zbnJ";
export var Container = "SectionOne-module--Container--2zzDK";
export var Webp = "SectionOne-module--Webp--2ZY8g";
export var ImageContainer = "SectionOne-module--ImageContainer---rAl2";
export var FontLogo = "SectionOne-module--FontLogo--14ivu";
export var FontLogoContainer = "SectionOne-module--FontLogoContainer--230hJ";
export var ScrollText = "SectionOne-module--ScrollText--3JgW2";
export var TopDown = "SectionOne-module--TopDown--12OvM";
export var flip3 = "SectionOne-module--flip3--Ro7IF";
export var show = "SectionOne-module--show--1lFti";