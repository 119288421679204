// extracted by mini-css-extract-plugin
export var SectionFour = "SectionFour-module--SectionFour--34ODk";
export var Container = "SectionFour-module--Container--3iEuW";
export var BackgroundContainer = "SectionFour-module--BackgroundContainer--1LEW8";
export var StartingAnimation = "SectionFour-module--StartingAnimation--1syDF";
export var Service = "SectionFour-module--Service--2Z0MN";
export var FlexStart = "SectionFour-module--FlexStart--GiCaL";
export var ActiveTab = "SectionFour-module--ActiveTab--1oCdq";
export var ServiceTab = "SectionFour-module--ServiceTab--3De1n";
export var FadeIn = "SectionFour-module--FadeIn--MMwZE";
export var ActionButton = "SectionFour-module--ActionButton--3uv8q";
export var ServiceDescription = "SectionFour-module--ServiceDescription--2DOcw";
export var DescPoint = "SectionFour-module--DescPoint--2BHnX";
export var ImageContainer = "SectionFour-module--ImageContainer--31xnv";
export var ScrollArrow = "SectionFour-module--ScrollArrow--3nk2t";
export var AddAnimation = "SectionFour-module--AddAnimation--1U2oO";
export var TopDown = "SectionFour-module--TopDown--7CeR9";
export var TopDown2 = "SectionFour-module--TopDown2--3jz3X";