// extracted by mini-css-extract-plugin
export var WrapperContainer = "SectionTwoTwo-module--WrapperContainer--33urP";
export var HideMobile = "SectionTwoTwo-module--HideMobile--8eP8R";
export var ContainerOne = "SectionTwoTwo-module--ContainerOne--Mnfco";
export var ContainerOneText = "SectionTwoTwo-module--ContainerOneText--2FK2j";
export var Mobile = "SectionTwoTwo-module--Mobile--1nl8A";
export var ContainerOneImage = "SectionTwoTwo-module--ContainerOneImage--HzZX4";
export var ScrollArrow = "SectionTwoTwo-module--ScrollArrow--2fs7E";
export var AddAnimation = "SectionTwoTwo-module--AddAnimation--3KgwO";
export var TopDown = "SectionTwoTwo-module--TopDown--1wwjW";
export var Desktop = "SectionTwoTwo-module--Desktop--3cZ0G";