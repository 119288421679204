import React, { useRef, useEffect } from "react";
import * as classes from "./PortfolioSection.module.css";
import ButtonLeft from "../../assets/images/others/line.png";
import ButtonRight from "../../assets/images/others/visit.png";
import Arrow from "../../assets/images/others/Group1button.png";
import PortfolioRhombus from "../../assets/images/background/Group 135.png";
import { Link } from "react-scroll";
import { ProjectsData } from "./ProjectsData";
import Content from "../../../content.json";
const PortfolioSection = () => {

  const imgRef = useRef();
  const salRef = useRef();

  useEffect(() => {
    salRef.current.addEventListener("sal:in", ({ detail }) => {
      imgRef.current.classList.add(classes.AddAnimation);
    });
  }, []);

  console.log(ProjectsData);
  return (
    <>
      <div className={classes.PortfolioSection} id="PortfolioSection">
        <div className={classes.ScrollContainer} id="PortfolioSection_Sub">
          <div className={classes.ProjectContainer}>
            <div className={classes.Project}>
              <div className={classes.Swipe}></div>
              <div className={classes.Container}>
                <div
                  className={classes.ProjectDescription}
                  data-sal="fade"
                  data-sal-delay="300"
                  data-sal-easing="ease"
                  ref={salRef}
                >
                  <div className={classes.ProjectLogo}>
                    <span><span>{Content.projectsPage.landingPage.heading.split(' ')[0]}</span> {Content.projectsPage.landingPage.heading.split(' ').slice(1).join(' ')}</span>
                  </div>
                  <div className={classes.Description}>
                    {Content.projectsPage.landingPage.content}
                  </div>
                  <div className={classes.ActionButton}>
                    <a href="/portfolio">
                      <button>
                        <img src={ButtonLeft} />
                        PORTFOLIO
                        <img src={ButtonRight} />
                      </button>
                    </a>
                  </div>
                  <div className={[classes.ScrollArrow, classes.HideDesktop].join(' ')}>
                    <Link
                      activeClass={classes.Active}
                      to="section4"
                      spy={true}
                      smooth={true}
                      offset={0}
                      duration={1000}
                    >
                      <img src={Arrow} alt="" ref={imgRef} />
                    </Link>
                  </div>
                </div>
                <div className={classes.ProjectDisplay}>
                  <div className={classes.PortfolioBackground}></div>
                  {ProjectsData.map(function (project, index) {
                    return (
                      <a href={"/portfolio?id=" + index} key={index} className={classes.LinkRhombus}>
                        <div className={classes.ImageRhombus}>
                          <div className={classes.ProjectBackgroundRhombus}>
                            <img src={PortfolioRhombus} />
                          </div>
                          <div className={classes.ImageDivRhombus}>
                            <img src={project.logo} alt={project.name} />
                          </div>
                        </div>
                      </a>
                    );
                  })}
                  {/*ProjectsData.map(function (project, index) {
                    return (
                      <a href={"/portfolio?id=" + index} key={index}>
                        <div className={classes.Image}>
                          <div className={classes.ProjectBackground}></div>
                          <div className={classes.ImageDiv}>
                            <img src={project.logo} alt={project.name} />
                          </div>
                        </div>
                        {/*<div className={classes.Dot}></div>*
                      </a>
                    );
                  })*/}
                </div>
                <div className={[classes.ActionButton, classes.HideDesktop].join(' ')}>
                  <a href="/portfolio">
                    <button>
                      <img src={ButtonLeft} />
                      PORTFOLIO
                      <img src={ButtonRight} />
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.ScrollArrow}>
        <Link
          activeClass={classes.Active}
          to="section2_2_desktop"
          spy={true}
          smooth={true}
          offset={0}
          duration={1000}
        >
          <img src={Arrow} alt="" ref={imgRef} />
        </Link>
      </div>
    </>
  );
};

export default PortfolioSection;
