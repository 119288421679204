import React, {useRef, useEffect} from "react"

import Arrow from '../../assets/images/others/Group1button.png';
import { Link } from "react-scroll";

import * as classes from './SectionTwoTwo.module.css';
//import SpreadedParticles from '../Particles/SpreadedParticles';
import Content from '../../../content.json'

const SectionTwoTwo=(props)=>{

    const imgRef = useRef();
    const salRef = useRef();
    const _id = props.desktop ? "section2_2_desktop" : "section2_2_mobile";
    useEffect(()=>{
        salRef.current.addEventListener('sal:in', ({ detail }) => {
          imgRef.current.classList.add(classes.AddAnimation);
        });
    },[])

    return(
      <div className={props.desktop ? classes.WrapperContainer : [classes.WrapperContainer, classes.HideMobile].join(' ') } id={_id}>
        <div className={classes.ContainerOne}>        
        <div className={classes.ContainerOneText}
          data-sal="fade"
          data-sal-delay="300"
          data-sal-easing="ease"
          ref={salRef}>
          <p>{Content.aboutUs.heading["2"]}</p>
          <p className={classes.Desktop}>
            {
              Content.aboutUs.content.split("<br/>").map(function(item){
                return <>{item}<br/></>
              })
            }
          </p>
          <p className={classes.Mobile}>
            {
              Content.aboutUs.content.split("<br/>").map(function(item){
                return <>{item}</>
              })
            }
          </p>
        </div>
      </div>
        <div className={classes.ScrollArrow}>
        <Link
                activeClass={classes.Active}
                to="section4"
                spy={true}
                smooth={true}
                offset={0}
                duration={1000}
          ><img src={Arrow} alt="" ref={imgRef}/></Link>
        </div>
      </div>
    )
}
export default SectionTwoTwo;